var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table', _vm._g(_vm._b({
    staticClass: "sygni-b-inner-table",
    attrs: {
      "innertable": "true",
      "fields": _vm.innerTableFields
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(code)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table__company"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$emit('openDetailsModal', rowData.item, _vm.items);
            }
          }
        }, [_vm._v(_vm._s(rowData.item.code))])])];
      }
    }, {
      key: "cell(seriesOfSharesName)",
      fn: function fn(rowData) {
        return [_c('span', [_vm._v(_vm._s(rowData.item.seriesOfSharesName ? rowData.item.seriesOfSharesName : 'N/A'))])];
      }
    }, {
      key: "cell(instrumentCount)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.instrumentCount, 0)) + " ")])])];
      }
    }, {
      key: "cell(nominalValue)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.nominalValue.value, 2)) + " ")])])];
      }
    }, {
      key: "cell(totalValue)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.totalValue.value, 2)) + " ")])])];
      }
    }, {
      key: "cell(invested)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.invested.value, 2)) + " " + _vm._s(rowData.item.invested.currency) + " ")])])];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }